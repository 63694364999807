import React from 'react';
// {useContext} from 'react';
// import { CanvasContext } from '../../store/CanvasState';
import '../styles/Circle.css';

const Circle = ({ ...props }) => {
  const { style, ...otherProps } = props;
  const index = otherProps.index;

  return (
    <div className="parent-div circle" {...otherProps} style={{...style, 'background' : 'transparent'}}>
         <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <circle id={index} className="div-circle" cx="50" cy="50" r="50" style={{ fill: style.background || 'lightgrey'}} />
        </svg>
      {(props.mode === 'select' || props.mode === 'edit') && props.children}
    </div>
  );
};

export default Circle;
