export const areSessionsIdentical = (sessionFromSupabase, session) => {
  // check if both has value
  // both has same number of keys
  // both has same keys and values
  if (sessionFromSupabase && session) {
    const keysSessionFromSupabase = Object.keys(sessionFromSupabase);
    const keysSession = Object.keys(session);
    if (keysSessionFromSupabase.length === keysSession.length) {
      const hasSameKeys = keysSessionFromSupabase.every((key) => keysSession.includes(key));
      if (!hasSameKeys) {
        return false;
      }

      const hasSameValues = keysSessionFromSupabase.every(
        (key) => sessionFromSupabase[key] === session[key],
      );
      if (!hasSameValues) {
        return false;
      }

      return true;
    }
  }
  return false;
};
