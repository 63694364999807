import React, { useState, useContext, useEffect } from 'react';
import '../styles/Paragraph.css';
import { CanvasContext } from '../../store/CanvasState';

/**
 *
 * @param {stylesObject} style
 * @param {props} otherProps
 */
export const getParagraphAttributes = (style, otherProps = {}) => {
  const combinedStyle = {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: '14px',
    width: 'auto',
    // overflow : "auto",
    minHeight: '100px',
    textAlign: 'left',
    display: 'flex',
    padding: '4px',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    // maxWidth: 'fit-content',
    ...style,
  };

  let attributes = {
    className: 'para para-input',
    ...otherProps,
    style: combinedStyle,
  };

  return attributes;
};

const Paragraph = ({ ...props }) => {
  const { style, ...otherProps } = props;
  const { canvasElements, delElem, updateElem } = useContext(CanvasContext);
  const index = otherProps.index;
  const elem = canvasElements[index];

  const [textValue, setTextValue] = useState(elem.value || '');

  useEffect(() => {
    setTextValue(elem.value || '');
      }, [elem.value]);

  const combinedStyle = {
    background: 'transparent',
    border: props.mode === 'view' ? 'none' : '1px solid lightblue',
    outline: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: '14px',
    width: 'auto',
    // overflow : "auto",
    minHeight: '100px',
    textAlign: 'left',
    // maxWidth: 'fit-content',
    ...style,
  };

  let attributes = {
    className: 'para para-input',
    ...otherProps,
    style: combinedStyle,
  };

  const handleEdit = (e) => {
    //Handle edit

    if (e.code === 'Enter') {
      e.preventDefault();
      elem.mode = 'view';
      elem.value = textValue;
      updateElem(elem, index);
    }

    // Trim so just white-space is treated as empty input
    if (e.code === 'Escape' && !textValue?.trim().length) {
      delElem(elem, index);
    }
  };

  const handleChange = (e) => {
    setTextValue(e.target.value);
    elem.value = e.target.value;
    updateElem(elem, index);
  };

  const handleClick = (e) => {
    canvasElements.map((ele) => {
      ele.mode = 'view';
    });

    if (e.detail === 2) {
      elem.mode = 'edit';
      updateElem(elem, index);
    } else {
      elem.mode = 'select';
      updateElem(elem, index);
    }
  };

  // const renderParagraph = () => {

  // }
  // console.log(canvasElements);
  if (attributes.mode === 'view' || attributes.mode === 'select') {
    return (
      <div id={index} className="para" {...attributes} onClick={handleClick}>
        <p>{textValue}</p>
        {props.children}
      </div>
    );
  } else if (attributes.mode === 'edit') {
    return (
      <div>
        <textarea
          {...attributes}
          onChange={handleChange}
          onKeyDown={handleEdit}
          onBlur={handleEdit}
          autoFocus
          placeholder="Enter your paragraph"
          value={textValue}
          cols={24}
          id={index}></textarea>
      </div>
    );
  }
};

export default Paragraph;
