// update all constants here. it is easy for auto-complete and can avoid typo error

export const HREF = 'href';
export const SRC = 'src';
export const FILL = 'fill';

// common
export const COLOR = 'color';

export const LAYERUP = 'layer_up';
export const LAYERDOWN = 'layer_down';


// font size and font type
export const FONT_SIZE = 'font_size';
export const FONT_TYPE = 'font_type';

// font decoration and font weight
export const BOLD = 'bold';
export const ITALIC = 'italic';
export const UNDERLINE = 'underline';

// heading
export const HEADING = 'heading';
export const TEXT_VARIANT = 'text_variant';

export const PARAGRAPH = 'paragraph';
export const SMALL_TEXT = 'small_text';

// paragraph
// nothing here for now

// button
export const LINK = 'link';
export const CORNER = 'corner';

export const EDIT = 'edit';