import Heading from './Heading';
import Paragraph from './Paragraph';
import Button from './Button';
import Image from './Image';
import Rectangle from './Rectangle';
import Circle from './Circle';
import Polygon from './Polygon';
import Star from './Star';
import Line from './Line';
import Text from './Text';

const elements = {
  Text,
  Heading,
  Paragraph,
  Button,
  Image,
  Rectangle,
  Circle,
  Polygon,
  Star,
  Line,
};

export default elements;
