import React, { useContext } from 'react';
import { CanvasContext } from '../store/CanvasState';
import defaultElementStyles from './defaultElementStyles.js';
import elementCategories from './elementCategories.js';
import './styles/Dropdown.css';
const Dropdown = ({ props }) => {
  const { dropdownPosition, setDropdownVisible, setActiveElement } = props;
  const { canvasElements, addElem } = useContext(CanvasContext);
  // const { canvasElements } = useContext(CanvasContext);

  const handleDropdownSelect = (elementName, e) => {
    e.stopPropagation();
    if (!elementName) {
  
      return;
    } else if (elementName) {
      const defaultElementsStyle = defaultElementStyles[elementName] || {};
      const mode = defaultElementsStyle.mode || 'edit';
      const newElementProps = {
        //id: new Date().getTime(),
        type: elementName,
        mode: mode,
        zIndex : canvasElements.length,
        style: {
          position: 'absolute',
          top: dropdownPosition.y + 'px',
          left: dropdownPosition.x + 'px',
          zIndex : canvasElements.length,
          ...defaultElementsStyle,
        },
        autoFocus: true,
        index: canvasElements.length,
      };
      addElem(newElementProps);
      setDropdownVisible(false);
      setActiveElement(canvasElements.length);
    }
  };

  return (
    <>
      <div
        className="dropdown"
        style={{
          position: 'absolute',
          top: dropdownPosition.y,
          left: dropdownPosition.x,
        }}>
        {/* <input type="text" placeholder="Type or select..." /> */}
        <span style={{'width' : '100%', 'display' : 'inline-block', 'textAlign' : 'left', 'color' : 'grey', 'paddingBottom' : '5px', 'position' : 'relative', 'left' : '5px'}}>
          Add new element
        </span>
        <ul className="main-dropdown">
          {elementCategories['Basic Elements'].map((elementName, j) => (
            <li
              className="pointer main-item"
              onClick={(e) => handleDropdownSelect(elementName, e)}
              key={j}>
              {elementName}
            </li>
          ))}
          {Object.keys(elementCategories)
            .filter((category) => category !== 'Basic Elements')
            .map((category, i) => (
              <li className="pointer main-item has-nested" key={i}>
                {category}
                <ul className="nested-dropdown">
                  {elementCategories[category].map((elementName, j) => (
                    <li
                      className="pointer nested-item"
                      onClick={(e) => handleDropdownSelect(elementName, e)}
                      key={j}>
                      {elementName}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default Dropdown;
