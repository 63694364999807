import React, { useState, useEffect, useContext } from 'react';
import '../styles/Heading.css';
import { CanvasContext } from '../../store/CanvasState';

/**
 *
 * @param {stylesObject} style
 * @param {props} otherProps
 */
export const getHeadingAttributes = (style, otherProps = {}) => {
  const combinedStyle = {
    background: 'transparent',
    outline: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: '32px',
    padding: '4px',
    width: 'auto',
    minHeight: 'inherit',
    ...style,
  };

  let attributes = {
    className: 'heading heading-input',
    ...otherProps,
    style: combinedStyle,
  };
  return attributes;
};
const Heading = ({ ...props }) => {
  const { style, ...otherProps } = props;
  const { canvasElements, delElem, updateElem } = useContext(CanvasContext);
  const index = otherProps.index;

  const elem = canvasElements[index];
  
  const [textValue, setTextValue] = useState(elem.value || '');

  useEffect(() => {
    setTextValue(elem.value || '');
      }, [elem.value]);

  const combinedStyle = {
    background: 'transparent',
    outline: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: '32px',
    padding: '4px',
    minHeight: 'inherit',
    ...style,
  };

  const inputStyles = {
    border: 'none',
    // outline: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: '32px',
    padding: '4px',
    minHeight: 'inherit',
    height: style.height,
    width: style.width,
  };

  let attributes = {
    className: 'heading heading-input',
    ...otherProps,
    style: combinedStyle,
  };

  const handleEdit = (e) => {
    //Handle edit

    // Update elem and enter view mode
    if (e.code === 'Enter' && textValue.length) {
      e.preventDefault();
      elem.mode = 'select';
      elem.value = textValue;
      updateElem(elem, index);
    }
    // Delete elem if value is empty and user presses Enter
    else if (e.code === 'Enter' && !textValue?.trim().length) {
      delElem(elem, index);
    }

    // Del elem if value is empty and user presses Esc key. Trim so just white-space is treated as empty input
    if (e.code === 'Escape' && !textValue?.trim().length) {
      delElem(elem, index);
    }
  };

  const handleChange = (e) => {
    setTextValue(e.target.value);
    props.onTextChange && props.onTextChange(e.target.value);
  };

  const handleClick = () => {
    // console.log('here');
    //  elem.mode = elem.mode === "view" ? "select" : "edit";
    //  updateElem(elem, index);
  };

  const renderHeading = () => {
    if (attributes.mode === 'view' || attributes.mode === 'select') {
      return (
        <p id={index} className={`heading ${attributes.mode}`} onClick={handleClick}>
          {textValue}
        </p>
      );
    } else {
      return (
        <input
          id={index}
          onChange={handleChange}
          onKeyDown={handleEdit}
          onBlur={handleEdit}
          autoFocus
          placeholder="Enter your heading"
          value={textValue}
          style={inputStyles}>
        </input>
      );
    }
  };

  return (
    <div className="div-heading" {...attributes}>
      {renderHeading()}
      {attributes.mode === 'select' && props.children}
    </div>
  );
};

export default Heading;
