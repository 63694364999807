import React from 'react';
import '../styles/Star.css';

const Star = ({ sides = 5, ...props }) => {
  const { style, ...otherProps } = props;
  const index = otherProps.index;

  const points = '50,0 61,35 100,35 67,57 80,100 50,75 20,100 33,57 0,35 39,35'

  const combinedStyle = {
    width: '100px', 
    height: '100px',  
    ...style,
  };

  return (
    <div className={`div-star sides-${sides}`} {...otherProps} style={{...combinedStyle, background: 'transparent'}}>
      <svg className='star' width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon id={index} className="inner-star" points={points} width={style.width} height={style.height} style={{'fill': style.background || 'lightgrey'}} />
      </svg>
      {(otherProps.mode === 'select' || otherProps.mode === 'edit') && props.children}
    </div>
  );
};

export default Star;
