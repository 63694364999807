import React from 'react';
// import '../styles/Line.css';

const Line = ({ orientation = 'horizontal', ...props }) => {
  const { style, ...otherProps } = props;
  const index = otherProps.index;

  let combinedStyle;

  // Horizontal line by default
  if (orientation === 'horizontal') {
    combinedStyle = {
      width: '100px',  // Default width
      height: '5px',  // Default height (thickness)
      background: 'black',
      ...style,
    };
  }

  // Vertical line
  if (orientation === 'vertical') {
    combinedStyle = {
      width: '5px',  // Default width (thickness)
      height: '100px',  // Default height
      background: 'black',
      ...style,
    };
  }

  let attributes = {
    ...otherProps,
    style: combinedStyle,
  };

  return (
    <div id={index} className={`div-line orientation-${orientation}`} {...attributes}>
      {(attributes.mode === 'select' || attributes.mode === 'edit') && props.children}
    </div>
  );
};

export default Line;
