import { HEADING } from './Toolbar/constants';

const defaultElementStyles = {
  Rectangle: {
    width: '200px',
    height: '200px',
  },
  Image: {
    width: '200px',
    height: '200px',
  },
  Button: {
    width: '112px',
    height: '32px',
  },
  Polygon: {
    width: '100px',
    height: '100px',
  },
  Circle: {
    width: '200px',
    height: '200px',
  },
  Star: {
    width: '200px',
    height: '200px',
  },
  Text: {
    variant: HEADING,
  },
  // Add more element types and their default styles here
};

export default defaultElementStyles;
