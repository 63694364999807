/* eslint-disable no-unused-vars, no-undef */
import React, { useContext, useState, useEffect } from 'react';
import { CanvasContext } from '../../store/CanvasState';

import imageDialogIcon from '../../assets/image-component/image-dialog-icon.svg';
import imageDialogFileIcon from '../../assets/image-component/upload-image-dialog.svg';
import '../styles/ImageDialog.css';
import { supabase } from '../../supabaseClient';
import { v4 as uuidv4 } from 'uuid';

const REACT_APP_ENV = process.env.REACT_APP_ENV;

const mimeToExtension = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/jpg' : '.jpg',
  'image/gif': '.gif',
  'image/webp': '.webp'
}

const fiveMegaBytes = 5242880;

const ImageDialog = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [imageLink, setImageLink] = useState(null);

  const storeUploadImage = async (e) => {
      const file = e.target.files[0];
      setImageFile(file);
      setImageLink(URL.createObjectURL(file));
  };

  const handleImageLinkInput = (e) => {
    setImageLink(e.target.value);
  };

  const saveImageHandler = async (e) => {
    if (imageFile) {
      const upload = imageFile;

      if (!mimeToExtension[upload.type]) {
        return;
      }

      if (upload.size > fiveMegaBytes) {
        return;
      }

      const uid = uuidv4();
      const { error } = await supabase
        .storage
        .from('images')
        .upload(`images/${uid + mimeToExtension[upload.type]}`, upload, { cacheControl: 3600, upsert: false })

      if (!error) {
        const { data } = supabase.storage.from('images').getPublicUrl(`images/${uid + mimeToExtension[upload.type]}`)
        props.getImageFile(data.publicUrl);
      }
    }
    else if (!imageFile && imageLink) {
      props.getImageFile(imageLink);
    }
  }

  return (
    <div className="imageDialog">
      <div className="imageDialog__header">
        <img src={imageDialogIcon} alt="image-icon" />
        <span className="header-image">Image</span>
      </div>
      <div className="imageDialog__content">
        <div className="imageDialog__content-link">
          <input
            type="text"
            className="image-link-box-input"
            placeholder="Paste image URL here."
            style={{'minWidth' : '60%', 'height' : '20px'}}
            value={imageLink} 
            onChange={handleImageLinkInput} 
            key="1"
            disabled = { imageFile ? true : false} 
          />
          
        </div>
        {  
            imageLink ? 
              <div style={{'width' : '300px', 'height' : '200px', 'overflow' : 'hidden', 'margin' : 'auto'}}>
                <img src={imageLink} style={{'width' : '100%', 'height' : '100%', 'objectFit' : 'contain'}}></img>
              </div> 
            : 
              <>
          <div>-OR-</div>
          <div className="imageDialog__file-upload">
            <img src={imageDialogFileIcon} />
            <input key="2" type="file" className="image-file-box-input" onChange={storeUploadImage} disabled={imageLink ? true : false}/>
            <div className="file-upload-text">Upload Image or Gif</div>
        </div>
        </>
        }  
      </div>
      <button style={{'position' : 'absolute', 'right' : '25px', 'bottom' : '25px', 'backgroundColor' : 'lightblue'}} onClick={saveImageHandler}>Save</button>
    </div>
  );
};

const Image = ({ ...props }) => {
  const { style, ...otherProps } = props;
  const { canvasElements, delElem, updateElem } = useContext(CanvasContext);
  const [imageNotLoaded, setImageNotLoaded] = useState(true);
  const index = otherProps.index;
  const elem = canvasElements[index];
  const [imageSrc, setImageSrc] = useState(elem.src || '');

  const combinedStyle = {
    minHeight: '100px',
    minWidth: '100px',
    ...style,
  };
  let attributes = {
    className: 'img',
    ...otherProps,
    style: combinedStyle,
  };

  // const handleImgClick = (e) => {
  //   canvasElements.map((elem) => {
  //     elem.mode = 'view';
  //   });
  //   if (e.detail === 2) {
  //     elem.mode = 'edit';
  //     updateElem(elem, index);
  //   } else {
  //     elem.mode = 'select';
  //     updateElem(elem, index);
  //   }
  // };

  const imageDialogBoxRender = () => {
    const getImageFile = (data) => {
      const imagePath = data.substring(80, data.length);
      const imageUrl = `https://${REACT_APP_ENV === 'development' ? 'dev-' : ''}storage.plexbox.app/images/images/${imagePath}`;
      setImageSrc(imageUrl);
      elem.mode = 'select';
      elem.src = imageUrl;
      updateElem(elem, index);
    };
    if (attributes.mode === 'edit' && !imageSrc) {
      return (
        <div>
          <ImageDialog getImageFile={getImageFile} />
        </div>
      );
    }
  };

  const renderImage = () => {
    const imgStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    };

    if(!imageSrc) delElem(elem, index);

    return (
      <>
        {
          imageNotLoaded ? <div>Loading image...</div> : null
        }
          <img 
            id={index} 
            style={imgStyle} 
            alt='image' 
            src={imageSrc} 
            onLoad={() => setImageNotLoaded(false)}
            draggable="false"
          /> 
      </>
    )
  };

  return (
    <div>
      <div {...attributes}>
        {/* {elem.mode === 'edit' ? imageDialogBoxRender() : ''}
        {imageSrc && renderImage()} */}
      {
        elem.mode === 'edit' ? 
          imageDialogBoxRender()
        :  
          renderImage()
      }
        {props.children}
      </div>
    </div>
  );
};

export default Image;
