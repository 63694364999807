import React from 'react';

const Rectangle = ({ ...props }) => {
  const { style, ...otherProps } = props;
  const index = otherProps.index;

  // const combinedStyle = {
  //   background: 'lightgray',
  //   minHeight: '100px',
  //   minWidth: '100px',
  //   ...style,
  // };

  // let attributes = {
  //   className: 'rectangle',
  //   ...otherProps,
  //   style: combinedStyle,
  // };

  return (
    <div id={index} className="div-rectangle" {...props}>
      <svg className="rectangle" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <rect id={index} width={style.width} height={style.height} style={{ fill: style.background || 'lightgrey' }} />
      </svg>
      {(props.mode === 'select' || props.mode === 'edit')  && props.children}
    </div>
  );
};

export default Rectangle;
