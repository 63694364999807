import React, { useState, useEffect , useContext} from 'react';
import './styles/PublishModal.css';
import { supabase } from '../supabaseClient';
import { CanvasContext } from '../store/CanvasState';

const REACT_APP_ENV = process.env.REACT_APP_ENV;

const PublishModal = ({ projectId, projectName, onClose, isUniqueProjectName }) => {


    const [formData, setFormData] = useState(null);
    const [urlError, setUrlError] = useState('');
    const [currentUrl, setCurrentUrl] = useState(null);
    const { canvasElements } = useContext(CanvasContext);


    useEffect(() => {

        // Function to fetch the project details
        const fetchProjectDetails = async () => {
            const { error, data } = await supabase
            .from('projects')
            .select('id, name, url, status, pages (id, title,description)')
            .eq('id', projectId)
            .limit(1);

            if (error) {
                console.error('Error fetching project details:', error);
                return;
            }
            if (data) {
                
                setFormData({
                    url: data[0].url || projectName ,
                    title: data[0].pages[0].title || projectName ,
                    description: data[0].pages[0].description  || ''
                });
                setCurrentUrl(data[0].url)
            }

            const checkProjectName = async () => {
                if (!await isUniqueProjectName(data[0].url || projectName|| projectName)) {
                    setUrlError('This URL is not available :(');
                }
            };
        
            checkProjectName();
           
            
        };

        fetchProjectDetails();
       

    }, []);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        if (name === 'url') {
            setUrlError(''); // Clear the error message when user modifies the URL
        }
    };


    async function addSubdomain(newSubdomain) {
        // Fetch the current subdomain array for the first row
        const { data: currentData, error: fetchError } = await supabase
          .from('published_domains')
          .select('*');
          
        
        if (fetchError) {
          console.error('Error fetching subdomain array:', fetchError);
          return;
        }
        let updatedSubdomains = null;
        // Filter out the subdomain that needs to be deleted
        if (currentUrl)
             updatedSubdomains = currentData[0].subdomain.filter(sd => sd !== currentUrl);
       
        
        // Append the new subdomain to the existing subdomain array
        updatedSubdomains = [...updatedSubdomains, newSubdomain];
       

        
        // Update the first row with the new subdomain array
        const { error: updateError } = await supabase
          .from('published_domains')
          .update({ subdomain: updatedSubdomains })
          .match({ id: currentData[0].id })
          ;
          
        
        if (updateError) {
          console.error('Error updating subdomain array:', updateError);
          return;
        }
        
      }


    const handlePublish = async (e) => {
        e.preventDefault();
        if ( !await isUniqueProjectName(formData.url)) {
            setUrlError('This URL is not available :(');
        } else {
            // Proceed with the publishing process
            // save to DB
            // publish
            const updateProjectDetails = async () => {
                let { error } = await supabase
                  .from('projects')
                  .update({ status: 'publish', url:formData.url })
                  .eq('id', projectId)
                  .select();
        
                if (error) {
                  console.warn(error);
                  return;
        
                }

                let { error: err } = await supabase
                  .from('pages')
                  .update({ status: 'publish', title:formData.title, description:formData.description })
                  .eq('project_id', projectId)
                  .select();
        
                if (err) {
                  console.warn(error);
                  return;
        
                }

                const user = await supabase.auth.getUser();
                const userName = user.data.user.user_metadata.full_name.split(' ')[0]
              

                let {  error: err1 } = await supabase
                  .from('publish_details')
                  .upsert({ url: formData.url, title:formData.title, description:formData.description , author: userName },{onConflict: 'url'})
                  .select();
        
                if (err1) {
                  console.warn(error);
                  return;
        
                }

                addSubdomain(formData.url);

        
              }
              await updateProjectDetails();

              const subdomain = formData.url
              const user = await supabase.auth.getUser();
              const userName = user.data.user.user_metadata.full_name.split(' ')[0]
              const htmlContent = generateHtml(canvasElements, userName);
              const blob = new Blob([htmlContent], { type: 'text/html' });
              uploadHtmlToSupabase(blob);



                // Open HTML page with unique URL in new tab 
                // if dev
                if (REACT_APP_ENV === 'development') {
                    window.open('https://dev.plexbox.app/' + subdomain, '_blank');
                } else {
                    // if prod or env variable doesnt exist
                    window.open('https://' + subdomain + '.plexbox.app/', '_blank');
                }
                onClose(false);
        }

    };

    async function uploadHtmlToSupabase(blob) {
        //const fileName = `${formData.url}-${Date.now()}.html`;
        const fileName = `${projectId}.html`;
      
        const { data, error } = await supabase.storage
          .from('published_pages')
          .upload(fileName, blob,  {
            contentType: 'text/html',
            upsert: true // This will overwrite the file if it already exists
        });
       
      
        if (error) {
          console.error('Error uploading the file: ', error.message);
          return null;
        }
      
        console.log('File uploaded successfully: ', data);
        return data;
    }

    function generateHtml(json,author) {
    

        const styleToString = (styleObj) => Object.entries(styleObj)
        .map(([key, value]) => {
            // Convert camelCase to kebab-case for CSS properties
            const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
            return `${cssKey}:${value}`;
        })
        .join(';');
        
        // Generate meta tags
        const metaTags = `
        <title>${formData.title}</title>
        <meta name="description" content="${formData.description}">
        <meta name="author" content="${author}">
        `;

        const elementsHtml = json.map((element) => {
            
            const elementStyles = element.style ? `style="${styleToString(element.style)}"` : '';

            switch (element.type) {
                case 'Button': {
                    return `<button ${elementStyles}>${element.value || 'Button'}</button>`;
                }
                case 'Image': {
                    const altText = element.value || 'Image';
                    return `<img ${elementStyles} src="${element.src}" alt="${altText}" />`;
                }
                case 'Text': {
                    const textTag = element.style && element.style.variant === 'heading' ? 'h1' : 'p';
                    return `<${textTag} ${elementStyles}>${element.value}</${textTag}>`;
                }
                case 'Rectangle': {
                    return `<div ${elementStyles}></div>`;
                }
                case 'Circle': {
                    return `<div ${elementStyles}></div>`;
                }
                // Add more cases for other types if needed
                default: {
                    return '';
                }
            }
        }).join('');
         // Add container styles if provided
        const containerStyles = json.style ? `style="${styleToString(json.style)}"` : '';
        
        return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            ${metaTags}
        </head>
        <body>
        <div ${containerStyles}>${elementsHtml}</div>
    </body>
        </html>
    `;
    }
      
    


    return (
        <div>

            {formData && (
                <div className="publish-modal">
                    <div className="publish-modal-content">
                        <form className="form">
                            <h3 className="form-heading">Publish your page</h3>
                            <div className="form-row">
                                <label>URL</label>
                                <div className="input-container">
                                    {urlError && <div className="url-error">{urlError}</div>}
                                    <div className="input-field publish-modal__input">
                                        <input
                                            type="text"
                                            name="url" 
                                            value={formData.url}
                                            onChange={handleInputChange}
                                            placeholder=""
                                            className={urlError ? 'error-input' : ''}
                                        />
                                        <span>.plexbox.app</span>
                                    </div>
                                    <div className="suggestions">
                                        <span className="suggestions-label">Alternative Suggestions:</span>
                                        <div className="suggestions-items">
                                            <span>buildspace1</span>
                                            <span>buildspace2</span>
                                            <span>buildspace3</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <label>Title</label>
                                <input
                                    name="title" 
                                    type="text"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    className="publish-modal__input"
                                />
                            </div>
                            <div className="form-row">
                                <label>Description</label>
                                <textarea
                                    name="description" 
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    className="publish-modal__textarea"
                                ></textarea>
                            </div>

                            <div className="publish-modal__actions">
                                <button className="publish-modal__button publish-modal__button--cancel" type="button" onClick={() => onClose(false)}>Cancel</button>
                                <button className="publish-modal__button publish-modal__button--submit" type="submit" onClick={handlePublish}>Publish</button>
                            </div>

                        </form>
                    </div>

                </div>
            )}
        </div>
    );
};

export default PublishModal;