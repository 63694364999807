import React, { useState } from 'react';
import './styles/Land_page.css';
import gameboyGif from '../assets/gifs/gameboy.gif';
import pikaGif from '../assets/gifs/pika.gif';
import { supabase } from '../supabaseClient';

const insertData = async (data) => {
  const { error } = await supabase.from('emails').insert([{ ...data }]);

  if (error) {
    console.error('Error: ', error);
  } else {
    console.log('Email submitted successfully!');
  }
};

// Call this function when your form is submitted
// insertData({ email: 'user@email.com' })

const Land_page = () => {
  const [isPressed, setIsPressed] = useState(false);
  // const [name, setName] = useState('');
  const [submit, setSubmit] = useState(false);
  const [emails, setEmails] = useState([]);
  const [email_id, setEmail_id] = useState('');
  const [invalidEmail, setInvalidEmail] = useState('');

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (value.length >= 0) {
      setInvalidEmail('');
      setSubmit(false);
    }
    setEmail_id(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g;

    if (email_id.match(email_regex) && !invalidEmail) {
      if (emails.filter((email) => email === email_id).length) {
        setInvalidEmail('exists');
        setSubmit(false);
      } else {
        setEmails([...emails, email_id]);
        setSubmit(true);
        insertData({ email_id: email_id });
      }
    } else {
      setInvalidEmail('invalid');
    }
  };

  const handleCardButton = () => {
    setInvalidEmail('');
    setSubmit(false);
    setEmail_id('');
  };

  return (
    <div className="landing-page">
      <div className="text-container">
        <p className="game-text">
          Plex<span className="box-text">box</span> invites you to think <br /> &rdquo;outside the
          box&rdquo;
        </p>
      </div>
      <div className="gif-cta">
        <img src={gameboyGif} alt="gameboy-gif" />
        <div className="cta-text">
          <div className="text">
            <p className="typewriter-text">
              ❯ in a world full of templates, why <br /> not create your own little unique space?
            </p>
          </div>
          <div className="cta">
            <input
              placeholder="Enter your Email ID"
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              value={email_id}
            ></input>
            <div
              className={`btn-div ${isPressed ? 'clicked' : 'default'} pointer`}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <button onClick={handleSubmit}>ENTER</button>
            </div>
          </div>
          {submit && !invalidEmail && (
            <div className="confirmation-text">
              <p>
                see you on the other side {`${email_id.substring(0, email_id.indexOf('@'))}`},{' '}
                <br /> meanwhile, you can follow us on{' '}
                <a href="https://twitter.com/plexboxapp" target="_blank" rel="noreferrer">
                  Twitter
                </a>{' '}
                :D
              </p>
              <div className="pika-gif">
                <img src={pikaGif} alt="pika gif" />
              </div>
              <button className="card-close-btn" onClick={handleCardButton}>
                x
              </button>
            </div>
          )}
          {invalidEmail && !submit && (
            <div className="invalid-email">
              <ul>
                <li>
                  {invalidEmail === 'exists'
                    ? 'you already submitted this email :@'
                    : 'invalid email :('}
                </li>
              </ul>
              <button className="card-close-btn pointer" onClick={handleCardButton}>
                x
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Land_page;
