import React, { useContext, useState } from 'react';
import deleteIcon from '../../assets/icons/delete-tool.png';
import { CanvasContext } from '../../store/CanvasState';
import '../styles/Toolbar.css';
import toolDefinitions from './toolDefinitions.js';
import { supabase } from '../../supabaseClient';

import {
  BOLD,
  COLOR,
  FILL,
  LAYERUP,
  LAYERDOWN,
  FONT_SIZE,
  FONT_TYPE,
  HEADING,
  HREF,
  ITALIC,
  LINK,
  UNDERLINE,
  TEXT_VARIANT
} from './constants';

import LinkComponent from './LinkComponent';

const getToolsLibrary = (activeElement) => {
  const conditionalTextOptions = activeElement?.style?.variant === HEADING ? HEADING : FONT_SIZE;
  return {
    Heading: [HEADING, COLOR, FONT_TYPE, BOLD, ITALIC, UNDERLINE, LAYERUP, LAYERDOWN],
    Text: [TEXT_VARIANT, conditionalTextOptions, COLOR, FONT_TYPE, BOLD, ITALIC, UNDERLINE, LAYERUP, LAYERDOWN],
    Paragraph: [FONT_SIZE, FONT_TYPE, BOLD, ITALIC, UNDERLINE, LAYERUP, LAYERDOWN],
    Button: [LINK, COLOR, FONT_SIZE, LAYERUP, LAYERDOWN],
    Image: [LAYERUP, LAYERDOWN],
    Rectangle: [FILL, LAYERUP, LAYERDOWN],
    Circle: [FILL, LAYERUP, LAYERDOWN],
    Polygon: [FILL, LAYERUP, LAYERDOWN],
    Star: [FILL, LAYERUP, LAYERDOWN],
    Line: [FILL, LAYERUP, LAYERDOWN]
  };
};

// Array of custom properties for an element
// eslint-disable-next-line no-unused-vars
const customProperties = [HREF, LAYERUP];

const deleteImageFromDB = async (elem) => {
  const imgSrc = elem.src.split('/').pop();
  const { error } = await supabase
      .storage
      .from('images')
      .remove([`${imgSrc}`]);

  if (error) {
      console.error('Error deleting image: ', error);
  } else {
      console.log('Image deleted successfully');
  }
}

const Toolbar = ({ style, elemType, activeElemIndex, setActiveElement }) => {
  const { canvasElements, updateElem, delElem } = useContext(CanvasContext);


  // Manage the state of showing/not showing, the Link Component
  const [showLinkComponent, setShowLinkComponent] = useState(false);

  const activeElem = canvasElements[activeElemIndex];
  let { top, left, position } = style;
  top = parseInt(top.replace('px', '')) - 30;

  const handleCustomProperty = (tool) => {
    // Handle the customProperty logic
    // On Link Button Click, whether to show/not show LinkComponent
    if (tool.customProperty === HREF) {
      showLinkComponent === true ? setShowLinkComponent(false) : setShowLinkComponent(true);
    }
    else if (tool.customProperty === LAYERUP) {
      let currentValue = activeElem.style[tool.styleKey];
      let updatedVal = currentValue < 1000 ?  currentValue + 1 : currentValue;
      updateElem(
        {
          ...activeElem, 
          zIndex : updatedVal,
          style : { ...activeElem.style, [tool.styleKey] : updatedVal},
        },
          activeElemIndex
      );
    }
    else if (tool.customProperty === LAYERDOWN) {
      let currentValue = activeElem.style[tool.styleKey];
      let updatedVal = currentValue > 0 ?  currentValue - 1 : currentValue;
      updateElem(
        {
          ...activeElem, 
          zIndex : updatedVal,
          style : { ...activeElem.style, [tool.styleKey] : updatedVal},
        },
          activeElemIndex
      );
    }
  };

  const handleUpdate = (tool, event) => {
    if (tool.type === 'button') {
      //Different Handler for custom properties
      if ('customProperty' in tool) {
        handleCustomProperty(tool);
      } else {
        const currentValue = activeElem.style[tool.styleKey];
        const newValue = currentValue === tool.onValue ? tool.offValue : tool.onValue;
        updateElem(
          {
            ...activeElem,
            style: { ...activeElem.style, [tool.styleKey]: newValue },
          },
          activeElemIndex,
        );
      }
    } else if (tool.type === 'dropdown') {
      const selectedValue = tool.values[event.target.value];
      updateElem(
        {
          ...activeElem,
          style: { ...activeElem.style, [tool.styleKey]: selectedValue },
        },
        activeElemIndex,
      );
    } else if (tool.type === 'input') {
      const suffix = tool.suffix || ''; // in case of link, there won't be any suffix.
      updateElem(
        {
          ...activeElem,
          style: {
            ...activeElem.style,
            [tool.styleKey]: event.target.value + suffix,
          },
        },
        activeElemIndex,
      );
    }
  };

  const isToolActive = (tool) => {
    if (tool.type === 'button') {
      // Check whether Link button should show as highlighted or not based on href value in store
      if ('customProperty' in tool) {
        if(tool.customProperty === HREF) {
          let href = canvasElements[activeElemIndex]?.href;
          return href && href.length > 0;
        }
        else if(tool.customProperty === LAYERUP || tool.customProperty === LAYERDOWN){
          return false;
        }
      }
      const bool = activeElem.style[tool.styleKey] === tool.onValue;
      return bool;
    }
    return false;
  };

  const handleDelete = () => {
    if(activeElem.type === 'Image') deleteImageFromDB(activeElem);
    delElem(activeElem, activeElemIndex);
    setActiveElement(null);
  };

  const currentTools = getToolsLibrary(activeElem)[elemType].map(
    (toolKey) => toolDefinitions[toolKey],
  );

  return (
    <>
      <div className="toolbar" style={{ top, left, position }}>
        {currentTools.map((tool, index) => {
          if (tool.type === 'button') {
            const activeClass = isToolActive(tool) ? 'active' : 'inactive';
            return (
              <button
                key={index}
                className={`toolbar-btn-${tool.label} ${activeClass}`}
                onClick={() => handleUpdate(tool)}>
                {tool.icon ? (
                  <img className="icon" src={tool.icon} alt="icon" />
                ) : (
                  tool.displayValue
                )}
              </button>
            );
          } else if (tool.type === 'dropdown') {
            return (
              <select
                key={index}
                className={`toolbar-dropdown-${tool.label}`}
                onChange={(event) => handleUpdate(tool, event)}
                value={Object.keys(tool.values).find(
                  (key) => tool.values[key] === activeElem.style[tool.styleKey],
                )}>
                {tool.options.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>
            );
          } else if (tool.type === 'input') {
            const inputType = tool.inputType || 'number'; // default to "number" if not provided
            return (
              <div key={index} className={`toolbar-input ${tool.label}`}>
                <input
                  style={tool.toolStyle}
                  type={inputType}
                  onChange={(event) => handleUpdate(tool, event)}
                  value={
                    tool.inputType === 'color'
                      ? activeElem.style[tool.styleKey] || '#d3d3d3'
                      : parseInt(activeElem.style[tool.styleKey] || tool.onValue)
                  }
                  {...(inputType === 'number' && {
                    min: tool.min,
                    max: tool.max,
                  })}
                />
                {tool.suffix}
              </div>
            );
          }
          // else if (tool.type === 'img') {
          //   return <div key={index} className={`toolbar-input ${tool.label}`}></div>;
          // }
        })}
        <button className="toolbar-btn-del" onClick={handleDelete}>
          <img className="icon" src={deleteIcon} alt="delete icon" />
        </button>
      </div>
      {/* Show the HyperLink Component  when clicking on the linking button in Toolbar */}
      {showLinkComponent && (
        <LinkComponent
          props={{
            canvasElements,
            updateElem,
            activeElemIndex,
            setShowLinkComponent,
            top,
            left,
            position,
          }}
        />
      )}
    </>
  );
};

export default Toolbar;
