import React from 'react';
import '../styles/Polygon.css';

const Polygon = ({ sides = 3, ...props }) => {
  const { style, ...otherProps } = props;
  const index = otherProps.index;

  let combinedStyle;

  const points = sides === 3 ? '50,0 100,100 0,100' : '';

  // Triangle by default
  if (sides === 3) {
    combinedStyle = {
      width: '100px',  // Default width
      height: '100px',  // Default height
      ...style,
    };
  }

  // Square
  if (sides === 4) {
    combinedStyle = {
      background: 'lightgray',
      ...style,
    };
  }

  return (  
    <div className={`div-polygon sides-${sides}`} {...otherProps} style={{...combinedStyle, background : 'transparent'}}>
      <svg className="triangle" width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon id={index} className='inner-polygon' points={points} width={style.width} height={style.height} style={{'fill' : style.background || 'lightgrey'}}/>
      </svg>
      {(props.mode === 'select' || props.mode === 'edit') && props.children}
    </div>
  );
};

export default Polygon;
