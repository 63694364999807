/* eslint-disable no-constant-condition */
import React, { useContext, useEffect } from 'react';
import { CanvasContext } from '../../store/CanvasState';
import { HEADING } from '../Toolbar/constants';
import '../styles/Heading.css';
import { getHeadingAttributes } from './Heading';
import { getParagraphAttributes } from './Paragraph';

const Text = ({ ...props }) => {
  const { style: s, ...otherProps } = props;
  const { ...style } = s;
  const { canvasElements, delElem, updateElem } = useContext(CanvasContext);
  const index = otherProps.index;

  const elem = canvasElements[index];

  const editorRef = React.createRef();

  useEffect(() => {
    if (editorRef && editorRef.current) {
      editorRef.current.textContent = elem.value || 'Enter your text';
    }
  }, [elem.value]);

  useEffect(() => {
    if (elem.mode === 'edit' && editorRef && editorRef.current?.textContent?.length === 0) {
      editorRef.current.focus();
      // set p tag's setSelectionRange to end of text
      // if (editorRef.current.childNodes.length > 0) {
      //   const range = document.createRange();
      //   const sel = window.getSelection();
      //   range.setStart(editorRef.current.childNodes[0], editorRef.current.textContent.length);
      //   range.collapse(true);
      //   sel.removeAllRanges();
      //   sel.addRange(range);
      // }
    }
  }, [elem.mode]);
  let attributes =
    style.variant === HEADING
      ? getHeadingAttributes(style, otherProps)
      : getParagraphAttributes(style, otherProps, elem.mode);

  const handleEdit = (e) => {
    //Handle edit

    const textValue = e.target.textContent || 'Enter your text';

    // Update elem and enter view mode
    if ((e.code === 'Enter' || e.type === 'blur') && textValue.length) {
      props.onTextChange && props.onTextChange(textValue || '');
      // console.log('textvalue final', textValue);
      e.preventDefault();
      elem.mode = 'select';
      elem.value = textValue;
      updateElem(elem, index);
      editorRef?.current?.blur();
    }
    // Delete elem if value is empty and user presses Enter
    else if (e.code === 'Enter' && !textValue?.trim().length) {
      delElem(elem, index);
    }

    // Del elem if value is empty and user presses Esc key. Trim so just white-space is treated as empty input
    if (e.code === 'Escape' && !textValue?.trim().length) {
      delElem(elem, index);
    }
  };

  const handleClick = (e) => {
    //  elem.mode = elem.mode === "view" ? "select" : "edit";
    canvasElements.map((ele) => {
      ele.mode = 'view';
    });

    if (e.detail === 2) {
      elem.mode = 'edit';
      editorRef.current.focus();
      // set p tag's setSelectionRange to end of text
      if (editorRef.current.childNodes.length > 0) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(editorRef.current.childNodes[0], editorRef.current.textContent.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }

      updateElem(elem, index);
    } else {
      elem.mode = 'select';
      updateElem(elem, index);
    }
    updateElem(elem, index);
  };
  //

  const renderText = () => {
        return (
      <p
        id={index}
        ref={editorRef}
        className={`${style.variant === HEADING ? 'heading' : 'para'} ${attributes.mode}`}
        onClick={handleClick}
        contentEditable
        style={{
          flex: 1,
          outline: 'none',
          overflow: 'hidden',
          height: style.height,
          width: style.width,
        }}
        onKeyDown={handleEdit}
        onBlur={handleEdit}>
        {/* for renderig on load  */}
        {editorRef.current?.textContent}
      </p>
    );
  };

  return (
    <div className="div-heading" {...attributes}>
      {renderText()}
      {attributes.mode === 'select' && props.children}
    </div>
  );
};

export default Text;
