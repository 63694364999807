import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// css
import './App.css';

// components
import Land_page from './components/Land_page';
import Home from './components/Home';
import Header from './components/Header';
import Canvas from './components/Canvas';
import RequireAuth from './components/auth/RequireAuth';
import Discover from './components/Discover';

// store and utils
import { CanvasProvider } from './store/CanvasState';
import { areSessionsIdentical } from './utils';

// supabase backend
import { supabase } from './supabaseClient';
import UserContext from './contexts/user';

function App() {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session: sessionFromSupabase } }) => {
      if (sessionFromSupabase) {
        setUser(sessionFromSupabase.user);
        setSession(sessionFromSupabase);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, sessionFromSupabase) => {
      // compare both sessionFromSupabase and session to avoid unnecessary updates
      if (!areSessionsIdentical(sessionFromSupabase, session)) {
        setSession(sessionFromSupabase);
        setUser(sessionFromSupabase.user);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, session, setUser, setSession }}>
      <div className="App">
        <Header session={session}></Header>
        <Router>
          <Route
            path="/"
            exact
            render={() => (session ? <Home userId={user.id} /> : <Land_page />)}
          />
          <Route path="/discover" exact>
          
            {
               <Discover /> 
            }
        
        </Route>
          <Route path="/projects/:url">
            <RequireAuth supabase={supabase}>
              <CanvasProvider>
                <Canvas />
              </CanvasProvider>
            </RequireAuth>
          </Route>
        </Router>
      </div>
    </UserContext.Provider>
  );
}

export default App;
