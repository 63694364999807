import React from 'react';
import Logo from '../assets/logo.png'
import './styles/Header.css';
import { supabase } from '../supabaseClient';
//import { useHistory } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'


const Header = ({ session }) => {
    // const generateCode = () => {
    //     // setCursor('select');
    //     console.log(document.getElementById('canvas'));
    // };
   // const history = useHistory()
    
    

    const logoutUser = async () => {
        const { error } = await supabase.auth.signOut()
        if (error) {
            console.warn(error)
            return
        }
        // Redirect to root URL
        window.location.href = '/';
    }

    return (
    <>
        <div className='nav-bar'>
            
                <div className='app-logo pointer' onClick={() => window.location.href = '/'}>
                    <img src={Logo} alt='Plexbox Logo'/>
                    <h2>Plexbox</h2>
                    

                    

                </div>
                <a className="discover-link app-logo" onClick={() => window.location.href = '/discover'}><h2>Discover</h2></a>
                
          
            
            {/* <div className='controlBgColor'>
                <p>Background Color : </p>
                <button className='colorBlock' onClick={changeShowPicker} style={{backgroundColor : bgColor}}></button>
                <div className='colorPicker' style={{visibility : showPicker ? 'visible' : 'hidden'}}> 
                {COLORS.map((color, index) => <button className='colorBlock' id={color} key={color} onClick={changeBgColor} style={{backgroundColor : color, border : color === bgColor ? '3px solid lightblue' : null} }></button>)}
                </div>
            </div> */}
                {/* <button className='gen-code' onClick={generateCode}>Generate Code</button> */}
               
               {/* Old Nav Bar Code */}
                {/* <div className='nav-bar-right'>
                    <h3 className='studio-link' style={{ visibility: window.location.pathname === '/' ? 'visible' : 'hidden' }}><a href='/home'>Studio (Pre-Alpha)</a></h3>

                    {
                        session ? <h4>{session.user.user_metadata.full_name}</h4> : <></>
                    }
                    {
                         session ?
                            
                            <h3 className='logout studio-link'>
                                
                                <a onClick={logoutUser}>Logout</a>
                            </h3> : ( window.location.pathname === '/' && <>
                            <div className='google-login'>
                    <Auth providers={['google']} onlyThirdPartyProviders supabaseClient={supabase} redirectTo={`${window.location.origin}/home`} appearance={{ theme: ThemeSupa }} />
                    </div></>)
                    
                    }
                 
                    
                </div> */}

                {/* New Nav Bar Code */}
                <div className='nav-bar-right'>
                    {/* <h3 className='studio-link' style={{ visibility: window.location.pathname === '/' ? 'visible' : 'hidden' }}>
                        <a href='/home'>Studio (Pre-Alpha)</a>
                    </h3> */}
                    {
                        session ? 
                        <>
                            <div className='user-dropdown'>
                            <h4>{session.user.user_metadata.full_name.split(' ')[0]}
                                <span className='dropdown-arrow'>&#9660;</span>
                            </h4>
                            <div className='user-dropdown-content'>
                                <a onClick={() => window.location.href = '/'}>My Projects</a>
                                <a onClick={logoutUser}>Logout</a>
                            </div>
                            </div>
                        </>
                        : <></>
                    }
                    {
                        session ?
                        <></>
                        : ( window.location.pathname === '/' &&
                        <div className='google-login'>
                            <Auth providers={['google']} onlyThirdPartyProviders supabaseClient={supabase} redirectTo={`${window.location.origin}`} appearance={{ theme: ThemeSupa }} />
                        </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default Header;