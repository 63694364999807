/* eslint-disable no-unused-vars, no-undef */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { supabase } from '../supabaseClient';

import './styles/Home.css'
import plusIcon from '../assets/icons/plus-icon.png'
import fileIcon from '../assets/icons/file-icon.png'
import { useHistory } from 'react-router-dom';
import { generate } from 'random-words';
import deleteIcon from '../assets/icons/delete-tool.png';
import deleteConfirmIcon from '../assets/icons/delete-project.png';
import openTabIcon from '../assets/icons/opentab-icon.png'

const REACT_APP_ENV = process.env.REACT_APP_ENV;

const Home = ({ userId }) => {

  const [projects, setProjects] = useState([])
  const history = useHistory()

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const COLORS = [
    '#FFB6C1', // Light Pink
    '#FFDAB9', // Peach Puff
    '#FFE4E1', // Misty Rose
    '#FFF0F5', // Lavender Blush
    '#FFFAF0', // Floral White
    '#F0E68C', // Khaki
    '#F0FFF0', // Honeydew
    '#F5FFFA', // Mint Cream
    '#F5F5DC', // Beige
    '#FAFAD2', // Light Goldenrod Yellow
    '#FFE4B5', // Moccasin
    '#FFF5EE', // Sea Shell
    '#FFFACD', // Lemon Chiffon
    '#FFF8DC', // Cornsilk
    '#F5F5F5', // White Smoke
    '#F0FFFF', // Azure
    '#E6E6FA', // Lavender
    '#FFF0F5', // Lavender Blush
    '#FFD700', // Gold (lighter)
    '#F0E68C', // Khaki (lighter)
    '#E0FFFF', // Light Cyan
    '#87CEFA', // Light Sky Blue
    '#B0E0E6', // Powder Blue
    '#ADD8E6', // Light Blue
    '#B0C4DE', // Light Steel Blue
    '#FFE4E1', // Misty Rose
    '#98FB98', // Pale Green
    '#AFEEEE', // Pale Turquoise
    '#D8BFD8', // Thistle
    '#F5DEB3', // Wheat];
  ];

  const projectStatusColorMapping = {
    'publish': '#9766FF',  
    'draft': '#458FE5',    
    
};
  const projectStatusTextMapping = {
    'publish': 'LIVE',
    'draft': 'DRAFT'
  };

  const getProjects = async () => {
    let { data, error } = await supabase
      .from('projects')
      .select(
        `
        id,
        user_id,
        url,
        status,
        name,
        pages ( id, modified_at )
      `).eq('user_id', userId)


    if (error) {
      console.warn(error);
      return;
    }

    if (data) {

      // Sort PROJECTS by to latest modified page
      const sortedProjects = data.sort((a, b) => {
        // Get the latest modified_at from project a's pages
        const latestModifiedAtA = a.pages.reduce((max, page) => {
          return new Date(page.modified_at) > new Date(max) ? new Date(page.modified_at) : max;
        }, new Date(0));
      
        // Get the latest modified_at from project b's pages
        const latestModifiedAtB = b.pages.reduce((max, page) => {
          return new Date(page.modified_at) > new Date(max) ? new Date(page.modified_at) : max;
        }, new Date(0));
      
        // Sort in descending order of modified_at
        return latestModifiedAtB - latestModifiedAtA;
      });
    
      

      return sortedProjects

    }
  };

  useEffect(() => {
    (async () => {
      const projectData = await getProjects();
      setProjects(projectData);
    })();

    return () => {};
    
  }, []);

  const handleNewProjectClick = async () => {

    let url = generate({ min: 1, max: 1, seed: new Date().getTime() })
    let projectName = url[0]

    const newProject = {
      user_id: userId,
      url: url[0],
      status: 'draft',
      name: url[0],
    };


    url = url[0]

    const { data, error } = await supabase.from('projects').insert(newProject).select()
  

    if (error) {
      console.warn(error);
      return;
    }

    const newPage = {
      route: '/',
      metadata: {},
      status: 'draft',
      project_id: data[0].id,
    };
    const { pageInsertError } = await supabase.from('pages').insert(newPage).select();

    if (error) {
      console.warn(pageInsertError);
      return;
    }
    const projectData = await getProjects();


    setProjects(projectData);
    history.push(`/projects/${projectName}?name=${projectName}&id=${data[0].id}`);

  };

  const handleProjectClick = (url, projectName,id) => {
    history.push(`/projects/${projectName}?name=${projectName}&id=${id}`);
  }; 

  async function deleteSubdomain(newSubdomain) {
    // Fetch the current subdomain array for the first row
    const { data: currentData, error: fetchError } = await supabase
      .from('published_domains')
      .select('*');
      
    
    if (fetchError) {
      console.error('Error fetching subdomain array:', fetchError);
      return;
    }
   
    
    // delete the new subdomain to the existing subdomain array
    const updatedSubdomains = currentData[0].subdomain.filter(sub => sub !== newSubdomain);
    // Update the first row with the new subdomain array
    const {  error: updateError } = await supabase
      .from('published_domains')
      .update({ subdomain: updatedSubdomains })
      .match({ id: currentData[0].id })
      ;
      
    
    if (updateError) {
      console.error('Error updating subdomain array:', updateError);
      return;
    }
    
  }

  async function deleteHtmlFromSupabase(fileName) {

    const { data, error } = await supabase.storage
        .from('published_pages')
        .remove([fileName]); // Pass an array of file paths to delete

    if (error) {
        console.error('Error deleting the file: ', error.message);
        return null;
    }

    console.log('File deleted successfully: ', data);
    return data;
}

  const handleDeleteProject = async () => {
    if (!projectToDelete) return;

    // Delete project entry
    const { data, error } = await supabase
      .from('projects')
      .delete()
      .select('url')
      .eq('id', projectToDelete.id);

    if (error) {
      console.warn(error);
      return;
    
    }

    console.log('delete', data)


    // Delete publish details entry
    let { error: errorDeletingPublishedPage } = await supabase
        .from('publish_details')
        .delete()
        .eq('url', data[0].url); // Replace 'project_id' with the appropriate column name

    if (errorDeletingPublishedPage) {
        console.warn('Error deleting published page:', errorDeletingPublishedPage);
        return;
    }

    // Delete HTML file from Supabase Storage
    const fileName = `${data[0].url}.html`; // Construct the file name
    await deleteHtmlFromSupabase(fileName);


    deleteSubdomain(projectToDelete.name)


    // Refresh the project list
    const projectData = await getProjects();
    setProjects(projectData);
    setShowDeleteModal(false); // Hide the modal
  };

  const DeleteModal = () => (
    <div className="delete-modal">
    <div className="modal-content">
      <img className="modal-icon" src={deleteConfirmIcon} alt="Warning" />
      <p className="modal-text">Are you sure you want to delete the project &quot;<b>{projectToDelete?.name}</b>&quot;?</p>
      <div className="modal-buttons">
        <button className="confirm-button" onClick={handleDeleteProject}>Delete</button>
        <button className="cancel-button" onClick={() => setShowDeleteModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
  );


  return (
    <div>
      <h1>Plexbox Studio (Pre-Alpha)</h1>
      {showDeleteModal && <DeleteModal />}
      {/* <h2>Your Projects</h2> */}
      <div className='projects-container'>
        <div id='yourProjects'>
            <div className='project-box new-project-box' onClick={handleNewProjectClick}>
              <img className='new-project-icon' src={plusIcon}></img>
              <div className='box-container'>
                <h3>New Project</h3>
              </div>
            </div>
            {   
              projects?.map((project, index) => {
                const bgColor = COLORS[index % COLORS.length];
                const imageURL = `https://${REACT_APP_ENV === 'development' ? 'dev-' : ''}storage.plexbox.app/images/screenshots/${project.name}.png`;
                console.log(imageURL);
                return (
                  <div 
                    className='project-box new-project-box' 
                    onClick={() => handleProjectClick(project.url, project.name,project.id)} 
                    key={project.id} 
                    style={{backgroundColor : bgColor}}
                  >
                    <div className='box-container' style={{
                      backgroundImage: `url('${imageURL}')`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height : '85%',
                      width : '85%',
                      position : 'absolute',
                      top : '0.8rem',
                      opacity : '0.8'
                    }}>
                      <button 
                        className='url-open-button'
                        onClick={(e) => {
                            e.stopPropagation();
                            if (project.status === 'draft') {
                              // Navigate to the edit page
                                window.location.href = `/projects/${project.name}?name=${project.name}&id=${project.id}`;
                            }
                            else {
                            if (REACT_APP_ENV === 'development') {
                              window.open('https://dev.plexbox.app/' + project.url, '_blank');
                            } else {
                              // if prod or env variable doesnt exist
                              window.open('https://' + project.url + '.plexbox.app/', '_blank');
                            }
                          }
                        }}
                        style={{backgroundColor: projectStatusColorMapping[project.status] || '#9766FF'}} // default to '#9766FF' if the status does not exist in the mapping
                      >
                          {project.status !== 'draft' && <img className="opentab-icon" src={openTabIcon} alt="Open in new Tab" />}
                          {projectStatusTextMapping[project.status] || 'UNKNOWN'} 
                      </button>
                      <img className='new-project-icon' src={fileIcon}></img>
                      <h3>{project.name}</h3>
                      <div 
                        className="delete-icon-box" 
                        onClick={(e) => {
                          e.stopPropagation();
                          setProjectToDelete(project);
                          setShowDeleteModal(true);
                        }}>
                        <img 
                          className='delete-icon' 
                          src={deleteIcon} 
                          alt="Delete" 
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   setProjectToDelete(project);
                          //   setShowDeleteModal(true);
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })
            }

        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
