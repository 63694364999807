import React, { useState, useEffect, useContext,useRef  } from 'react';
import './styles/Canvas.css';
import elements from './elements/Elements.js';
import { CanvasContext } from '../store/CanvasState';
import Toolbar from './Toolbar/Toolbar';
import Dropdown from './Dropdown';
import ActiveWrapper from './Wrappers/ActiveWrapper';
import { supabase } from '../supabaseClient';
import html2canvas from 'html2canvas';
import { useParams, useHistory } from 'react-router';
import { loadFromIndexedDB ,initializeIndexedDB,saveToIndexedDB } from '../store/utility';

import saveIcon from '../assets/icons/save-icon.png'
import editIcon from '../assets/icons/edit-icon.png'
import previewIcon from '../assets/icons/preview-icon.png'
import { useLocation } from 'react-router-dom';
import editNameIcon from '../assets/icons/edit-name-icon.png'
import publishIcon from '../assets/icons/publish-icon.png'
import PublishModal from './PublishModal';

const VIEW_MODE = 'view';
const EDIT_MODE = 'edit';
const CANVAS_HEIGHT = 80;
const elementNames = Object.keys(elements);

const Canvas = () => {

  const { canvasConfig, updateCanvasConfig, canvasElements,  updateElem, loadSavedState } =
    useContext(CanvasContext);

  const [currentCanvasHeight, setCurrentCanvasHeight] = useState(CANVAS_HEIGHT);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const [activeElement, setActiveElement] = useState(null);
  // const [downtime, setDowntime] = useState(0);

  const [isDragging, setIsDragging] = useState(false);

  const [pageMetadata, setPageMetadata] = useState({})
  const [previewMode, setPreviewMode] = useState(false)
  const [previewHtml, setPreviewHtml] = useState('')

  const [pageId, setPageId] = useState(null);
  const history = useHistory();
  

  const { url } = useParams();

  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const project_id = queryParams.get('id');
  const [projectName, setProjectName] = useState(null);
  //const [projectId, setProjectId] = useState(null);
  const inputRef = useRef(null);
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const [projectNameError, setProjectNameError] = useState(canvasConfig?.projectName); 

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const newName = params.get('name');
      //const id = params.get('id');
      if (newName !== projectName) {
        setProjectName(newName);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const inputElement = document.querySelector('.project-name-input');
    if (inputElement) {
      adjustWidth({ target: inputElement });
    }
    updateCanvasConfig({'projectName' : projectName})
  }, [projectName]);

  const handleProjectNameUpdate = (e) => {
    const newProjectName = e.target.value;
    const projectNamePattern = /^[a-zA-Z0-9-_]*$/;
  
    if (!projectNamePattern.test(newProjectName)) {
      setProjectNameError('Project name can only contain alphanumeric characters, hyphens, and underscores.');  // Step 3: Set error message
      return;
    }
    
    const updateProjectName = async () => {
      if (!await isUniqueProjectNameForUser()){
        setProjectNameError('Project already exists.');  // Step 3: Set error message
        return;
      }
      else {
        setProjectNameError(null);  // Clear error message if project name is valid
      }

      // Update the project name in database
      let { error } = await supabase
        .from('projects')
        .update({ name: newProjectName})
        .eq('id', project_id)
        .select();

      if (error) {
        console.warn(error);
        return;

      }
    }
    updateProjectName();
    const currentSearch = new URLSearchParams(location.search);
    // Split pathname into segments and replace the last segment
    const pathSegments = location.pathname.split('/');
    pathSegments[pathSegments.length - 1] = newProjectName;

    // Join the segments back together to form the new pathname
    const newPathname = pathSegments.join('/');
    currentSearch.set('name', newProjectName);
    history.push({
      pathname: newPathname,
      search: currentSearch.toString()
    });



   
    e.target.blur();
    setIsEditingProjectName(false);
  }

  function adjustWidth(e) {
    // Create a temporary element with the same styles as the input
    const inputElement = e.target;
    const tempElement = document.createElement('span');
    tempElement.style.font = window.getComputedStyle(inputElement, null).getPropertyValue('font');
    tempElement.style.opacity = 0;
    tempElement.style.position = 'absolute';
    tempElement.style.whiteSpace = 'nowrap';
    tempElement.innerHTML = inputElement.value;

    // Append to the body to make the element part of the document
    document.body.appendChild(tempElement);

    // Update the width of the input based on the text width
    inputElement.style.width = tempElement.offsetWidth + 'px';

    // Remove the temporary element from the body
    document.body.removeChild(tempElement);
  }

  const getPageData = async () => {

    const { error, data } = await supabase
      .from('projects')
      .select('id, name, url, status, pages (id, route, metadata,status, route)')
      .eq('id', project_id)
      .limit(1);

    if (error) {
      console.warn(error)
      return
    }
    if (!data || data.length === 0) {
      // No data returned, possibly due to RLS policy. Throw an unauthorized error.

      console.warn('Unauthorized');
      history.push('/');

      return;
    }
    const { pages } = data[0];
    return pages[0];
  };

  useEffect(() => {
    const setup = async () => {
      try {
        const pageData = await getPageData();

        if (!pageData) {
          return
        }
        setPageId(pageData.id)
        // Initialize IndexedDB if it doesn't exist
        await initializeIndexedDB();


        // Try to load data from IndexedDB
        const loadedData = await loadFromIndexedDB(url);

        if (loadedData) {

          // If data exists in IndexedDB, load it into the state
          loadSavedState(loadedData.elements ?? []);
          setPageMetadata(loadedData);
        } else {
          // If data doesn't exist in IndexedDB, fetch it from the backend

          const pageData = await getPageData();

          if (!pageData) {
            return
          }
          setPageId(pageData.id)
          const metadata = pageData.metadata
          metadata.style = {
            height: currentCanvasHeight + 'vh'
          }
          if(!metadata.elements)
            metadata.elements = [] ;
          if (metadata && Object.keys(metadata).length) {
            setPageMetadata(metadata)
            // Populate IndexedDB with data from the backend
            await saveToIndexedDB(metadata,url);

            // Load the data into the state
            loadSavedState(metadata.elements);
          }
        }
        setIsInitialDataLoaded(true);
      } catch (error) {
        console.error('Failed to initialize or load from DB:', error);
      }
    };

    setup();
  }, []);

  useEffect(() => {
    if (!isInitialDataLoaded) {
      return;  // Skip if initial data is not yet loaded
    }


    const updateIndexedDB = async () => {
      try {
        
        await saveToIndexedDB({ ...pageMetadata, elements: canvasElements },url);
        setPageMetadata({ ...pageMetadata, elements: canvasElements })
      } catch (error) {
          console.error('Failed to update IndexedDB:', error);
      }
    };

    updateIndexedDB();
  }, [canvasElements]);

  useEffect(() => {

    // (async () => {
    //   let pageData = await getPageData()
    //   if (!pageData) {
    //     return
    //   }
    //   setPageId(pageData.id)
    //   const metadata = pageData.metadata
    //   if (metadata && Object.keys(metadata).length) {
    //     setPageMetadata(metadata)
    //     metadata.elements.forEach((elementProps) => {
    //       const index = elementProps.index
    //       // replace with ID check later for strict uniqueness
    //       if (typeof canvasElements[index] === 'undefined') {
    //         //addElem(elementProps)
    //       }
    //     })
    //   }

    // })();


    //Close dropdown when open and user preses Esc key
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setDropdownVisible(false);
        // Will unselect the Active element
        if (activeElement != null) {
          unfocusActiveElement();
        }
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //unfocus the active element when opening dropdown or clicking outside the canvas
  const unfocusActiveElement = () => {
    const elem = canvasElements[activeElement];
    if (!elem) {
      return;
    }

    elem.mode = VIEW_MODE;
    updateElem(elem, activeElement);
    setActiveElement(null);
  };

  //handle canvas click to open dropdown or selecting element
  const handleCanvasClick = (e) => {
    const id = e.target.id;

    //unfocus current active element, put everything in view mode
    if (id === 'canvasContainer' && !e.metaKey && !e.ctrlKey && activeElement != null && activeElement >= 0 && (canvasElements).length > 0 && canvasElements[activeElement] && (canvasElements[activeElement].mode == EDIT_MODE || canvasElements[activeElement].mode == 'select')) {
      let elem = canvasElements[activeElement]
      elem.mode = 'view';
      unfocusActiveElement();
      updateElem(elem, activeElement);
      setDropdownVisible(false);
    }
    else if (id === 'canvasContainer' && dropdownVisible && !e.metaKey && !e.ctrlKey){
      setDropdownVisible(false);
    }
    //Click on canvas empty space, and no active element selected, so open dropdown
    // else if (id === 'canvasContainer') {
    else if (e.metaKey || e.ctrlKey){
      const canvasContainer = document.getElementById('canvasContainer');
      const bounds = canvasContainer.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;
      setDropdownPosition({ x: x, y: y });
      unfocusActiveElement();
      setDropdownVisible(true);
    }
    //Click on any of the elements, regardless of another elemented selected or not
    else if (id && parseInt(id) >= 0) {
      if (activeElement != null && activeElement != id) {
        let prevElem = canvasElements[activeElement];
        if (prevElem) {
          prevElem.mode = 'view';
          updateElem(prevElem, activeElement);
        }
        let elem = canvasElements[id];
        elem.mode = 'select';
        updateElem(elem, id);
      } else if (activeElement == null) {
        let elem = canvasElements[id];
        elem.mode = 'select';
        updateElem(elem, id);
      }
      setActiveElement(parseInt(id));
      setDropdownVisible(false);
    }
  };

  //add new section to the page for more content
  const handleNewSection = () => {
    setCurrentCanvasHeight(currentCanvasHeight + CANVAS_HEIGHT);
  };

  //remove last section on the page
  const handleDelSection = () => {
    setCurrentCanvasHeight(currentCanvasHeight - CANVAS_HEIGHT);
  };

  //handle clicks outside the canvas area
  const handleNonCanvasClick = (e) => {

    if (e.target.className === 'canvas-page') {

      unfocusActiveElement();
      setDropdownVisible(false);
    }
  };

  const captureCanvas = async () => {
    // Ensure the canvas element is passed in
    // let localUrl;

    try {
      // Use html2canvas to take a screenshot of the canvasContainer
      const canvas = await html2canvas(document.getElementById('canvasContainer'));
      // const canvas = document.getElementById('canvasContainer');
      canvas.toBlob(async (blob) => {
        // Create a file-like object from the Blob for Supabase
        const file = new File([blob], 'screenshot.png', {
          type: 'image/png',
        });
        // localUrl = URL.createObjectURL(file);

        // Upload the image to Supabase Storage
        const { data, error } = await supabase.storage
          .from('images') // Replace with your bucket name
          .upload(`screenshots/${projectName}.png`, file, { upsert: true });

        if (error) throw error;

        // Handle the URL after upload if needed
        console.log('File uploaded:', data);
        if (!error) {
          const { data } = supabase.storage.from('images').getPublicUrl(`screenshots/${projectName}.png`);
          console.log('screenshot upload successful!');
          return data;
        }
      });
    } catch (error) {
      console.error('Error taking a screenshot or uploading to Supabase:', error);
    }
  }

  const handleSave = async () => {
    let metadata = { ...pageMetadata };
    metadata.style = {
      height: currentCanvasHeight + 'vh',
    };
    metadata.elements = canvasElements.map((ele) => {
      ele.mode = 'view';
      return ele;
    });
    let { error } = await supabase
      .from('pages')
      .update({ metadata: metadata ,modified_at: new Date() })
      .eq('id', pageId)
      .select();

    if (error) {
      console.warn(error);
      return;
    }
    setPageMetadata({ ...pageMetadata, elements: canvasElements });
    
    //capture screenshot of the canvas
    captureCanvas();
  };

  const PreviewCanvas = () => {
    return (
      <div style={{ height: pageMetadata.style.height }} id='canvasContainerPreview' dangerouslySetInnerHTML={{ __html: previewHtml }}></div>
    )
  }

  const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())

  const switchPreviewMode = () => {
    if (!previewMode) {
      const previewElement = document.createElement('div')
      previewElement.setAttribute('class', 'previewCanvas')
      canvasElements.forEach(element => {
        let anchorElement;
        let newElement
        switch (element.type) {
          case 'Heading':
            newElement = document.createElement('div')
            break
          case 'Paragraph':
            newElement = document.createElement('div')
            break
          case 'Button':
            newElement = document.createElement('button')
            break
          case 'Image':
            newElement = document.createElement('img')
            newElement.src = element.href ? element.href : ''

            break
          default:
            newElement = document.createElement('div')
        }

        Object.keys(element).forEach((key) => {
          if (key === 'style') {
            let styles = ''
            Object.keys(element[key]).forEach(styleKey => {
              styles += `${kebabize(styleKey)}: ${element[key][styleKey]};`
            })
            newElement.style = styles
            if (element.type === 'Image') {
              newElement.style.width = '150px'
              newElement.style.height = newElement.style.width
            }
          }
          else if (key === 'value') {
            newElement.innerText = element[key]
          }
          else if (key === 'href'){ //link instead of href
            anchorElement = document.createElement('a');
            anchorElement.href = element[key];
            anchorElement.target = '_blank';
            
            //wrap button element  into an <a link so that clicking on it opens the url
            
          }
          else {
            newElement.setAttribute(key, element[key])
          }
        })
        //previewElement.appendChild(newElement)

        if (anchorElement) {
          anchorElement.appendChild(newElement);
          previewElement.appendChild(anchorElement);
        } else {
          previewElement.appendChild(newElement);
        }
      })
      setPreviewHtml(previewElement.innerHTML) 
    }
    setPreviewMode(!previewMode)
  }     

  const isUniqueProjectNameForUser = async () => {
    const user = await supabase.auth.getUser();
    const userId = user.data.user.id;
    let { data, error } = await supabase
      .from('projects')
      .select('name')
      .eq('name', projectName)
      .eq('user_id', userId)
      .neq('id', project_id);

    if (error) {
      console.warn(error);
      return false;
    }

    if(data && data.length > 0){
      return false;
    }else {
      return true;
    }
  };

  const isUniqueProjectName = async (publishUrl) => { //isUniquePublishedURL
    let { data } = await supabase
      .from('projects')
      .select('url')
      .eq('id', project_id);


    if (data[0].url == publishUrl){
      return true;
    }

    let {  error } = await supabase
    .from('published_domains')
    .select('subdomain')
    .contains('subdomain', `{${publishUrl}}`)
    .single();

    // no rows returns - unique
    if (error) {
      console.warn(error);
      return true;
    }
    
    else {

      return false;
    }
  };

  const publishPage = async () => {
      handleOpenModal();
  }

  //rendering the canvas
  return (
    // non-canvas area
    <div>
      {!previewMode && <div className='project-name-container'>
        <input className={`project-name-input ${projectNameError ? 'input-error' : ''}`} ref={inputRef} type='text' value={projectName}
          onInput={(e) => { adjustWidth(e); setProjectName(e.target.value); }}
          onBlur={handleProjectNameUpdate}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevents the default form submission if the input is inside a form
              inputRef.current.blur(); // Manually triggers onBlur by removing focus from the input
            }
          }}
          onFocus={(e) => { e.target.select(); setIsEditingProjectName(true); }}
        />
        <br></br>
        {projectNameError && <div className='error-message'>{projectNameError}</div>}


        {!projectNameError && !isEditingProjectName && <img className='project-icon' src={editNameIcon} alt='Project Icon'
          onClick={() => {
            inputRef.current.focus();
            inputRef.current.select();  //  to select all text inside input
          }}
        />}
      </div>}
      <div className='action-buttons'>
        <button onClick={handleSave} className='action-btn save-btn'>
          <img className='btn-icon save-icon' src={saveIcon}></img>
          Save
        </button>
        <button onClick={switchPreviewMode} className='action-btn preview-btn'>
          <img className='btn-icon preview-icon' src={previewMode ? editIcon : previewIcon}></img>
          {previewMode ? 'Edit' : 'Preview'}
        </button>
        <button onClick={publishPage} className='action-btn publish-btn'>
          <img className='btn-icon preview-icon' src={publishIcon}></img>
          Publish
        </button>
        {/* <h3 className='studio-link'>

          <a onClick={() => { history.push('/home') }}>My Projects</a>
        </h3> */}
      </div>
      {previewMode ? <PreviewCanvas /> :
        <div className='canvas-page' onClick={handleNonCanvasClick}>
          {/* Canvas area inside the canvasContainer. All elements rendered inside here */}

          {/* Canvas area inside the canvasContainer. All elements rendered inside here */}

          <div
            id={'canvasContainer'}
            onClick={handleCanvasClick}
            style={{ height: `${currentCanvasHeight/1.7}rem` }}
          >
            {/* Helper text to guide users to click anywhere */}
            {!canvasElements.length && !dropdownVisible && (
              <h1 style={{ opacity: '40%', marginTop: '25%' }}>
                Cmd/Ctrl + Click Anywhere To Create!
              </h1>
            )}

            {/* Render the elements on the canvas */}
            {canvasElements.map((elementProps, index) => {
              const Component = elements[elementProps.type];
              return (
                //Wrapper to manage active element styling and behaviour
                <div key={index} className='canvasElemDiv'>  
                  {elementProps.mode !== 'view' && isDragging === false && (
                    <Toolbar
                      style={elementProps.style}
                      elemType={elementProps.type}
                      activeElemIndex={elementProps.index}
                      setActiveElement={setActiveElement}
                    />
                  )}                            
                  <ActiveWrapper
                    isActive={activeElement === elementProps.index}
                    updateElem={updateElem}
                    activeElemIndex={activeElement}
                    setIsDragging={setIsDragging}
                    // onTextChange={handleTextChange}
                  >         
                    <Component {...elementProps} />
                  </ActiveWrapper>
                </div>
              );
            })}

            {/* Show the dropdown when clicking on empty space */}
            {dropdownVisible && (
              <Dropdown
                props={{
                  dropdownPosition,
                  elementNames,
                  setDropdownVisible,
                  setActiveElement,
                }}
              />
            )}
            <div>

              {isModalOpen && <PublishModal projectId={project_id} projectName={projectName} onClose={handleCloseModal} isUniqueProjectName={isUniqueProjectName} />}
            </div>
          </div>

          {/* Button to add another section. */}
          <div className='add-section-container'>
            <button className='add-section-btn' onClick={handleNewSection}>
              Add Section
            </button>
            {currentCanvasHeight > CANVAS_HEIGHT && (
              <button className='del-section-btn' onClick={handleDelSection}>
                Remove Section {currentCanvasHeight / CANVAS_HEIGHT}
              </button>
            )}
          </div>
        </div>
      }

    </div>
  );
};

export default Canvas;
