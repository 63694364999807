import React, { useState, useContext, useEffect } from 'react';
import { CanvasContext } from '../../store/CanvasState';
import '../styles/Button.css';

const Button = ({ ...props }) => {
  const { style, ...otherProps } = props;
  const { canvasElements, delElem, updateElem } = useContext(CanvasContext);
  const index = otherProps.index;
  const elem = canvasElements[index];

  const [buttonText, setButtonText] = useState(elem.value || '');

  useEffect(() => {
    setButtonText(elem.value || '');
  }, [elem.value]);

  const combinedStyle = {
    // minHeight: '2rem',
    // minWidth: '7rem',
    borderRadius: '8px',
    //fontSize: `${Math.min(style.height, style.width) * 0.5}px`,
    ...style,
  };

  let attributes = {
    className: 'button',
    ...otherProps,
    style: combinedStyle,
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (e) => {
    setButtonText(e.target.value);
    props.onTextChange && props.onTextChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      elem.mode = 'select';
      elem.value = buttonText;
      updateElem(elem, index);
    }

    // Trim so just white-space is treated as empty input
    if (e.code === 'Escape' && !buttonText?.trim().length) {
      delElem(elem, index);
    }
  };

  const handleView = () => {
    elem.mode = 'select';
    updateElem(elem, index);
  };

  const handleSelect = () => {
    elem.mode = 'edit';
    updateElem(elem, index);
  };

  // eslint-disable-next-line no-unused-vars
  const handleClick = () => {
    if (elem.href) {
      window.location = elem.href;
    }
  };

  if (attributes.mode === 'view') {
    return (
      <div className="btn-div">
        <button id={index} onClick={handleView} {...attributes} style={{...combinedStyle, 'outline' : 'none', 'borderRadius' : '5px'}}>
          {buttonText}
        </button>
        {props.children} {/* Render children here */}
      </div>
    );
  } else if (attributes.mode === 'select') {
    return (
      <div className="btn-div select-btn" {...attributes} style={{...style, 'display' : 'flex', 'justifyContent' : 'center', 'alignItems' : 'center'}}>
        <p
          id={index}
          onClick={handleSelect}
          onContextMenu={handleView}
          style={{
            'cursor' : 'text',
            'fontSize' : attributes.style.fontSize,
          }}
        >
          {buttonText}{' '}
        </p>
        {props.children} {/* Render children here */}
      </div>
    );
  } else {
    return (
      <div className="btn-div edit-btn"  {...attributes} style={combinedStyle}>
        <input
          id={index}
          style={{
            'width' : '100%', 
            'height' : '100%', 
            'outline' : 'none', 
            'border' : 'none', 
            'textAlign' : 'center',
            'padding' : '0px',
            'fontSize' : attributes.style.fontSize,
          }}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder="Enter Button Text"
          value={buttonText}
          autoFocus
        >
          {/* {buttonText}{' '} */}
        </input>
        {props.children} {/* Render children here */}
      </div>
    );
  }
};

export default Button;
