import React, { useState, useEffect } from 'react';

// Link Component - Add a HyperLink/href to an element
const LinkComponent = ({ props }) => {
  const { canvasElements, updateElem, activeElemIndex, setShowLinkComponent, top, left, position } =
    props;

  const [hrefText, setHrefText] = useState('');

  useEffect(() => {
    setHrefText(canvasElements[activeElemIndex]?.href || '');
  }, [canvasElements, activeElemIndex]);

  const handleApplyClick = (event) => {
    event.preventDefault();
    // Update the href value in store
    updateElem({ ...canvasElements[activeElemIndex], href: hrefText }, activeElemIndex);
    setShowLinkComponent(false); // Close the linkcomponent popup after submitting
  };

  return (
    <div className="popup-container" style={{ position, top: top - 40, left }}>
      <div className="popup">
        <input
          type="text"
          value={hrefText}
          onChange={(e) => setHrefText(e.target.value)}
          autoFocus
        />
        <button onClick={(event) => handleApplyClick(event)}>Apply</button>
      </div>
    </div>
  );
};

export default LinkComponent;
