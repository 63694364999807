import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { supabase } from '../supabaseClient';

import './styles/Discover.css'



const Discover = () => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV;

  const [projects, setProjects] = useState([])




  const COLORS = [
    '#FFB6C1', // Light Pink
    '#FFDAB9', // Peach Puff
    '#FFE4E1', // Misty Rose
    '#FFF0F5', // Lavender Blush
    '#FFFAF0', // Floral White
    '#F0E68C', // Khaki
    '#F0FFF0', // Honeydew
    '#F5FFFA', // Mint Cream
    '#F5F5DC', // Beige
    '#FAFAD2', // Light Goldenrod Yellow
    '#FFE4B5', // Moccasin
    '#FFF5EE', // Sea Shell
    '#FFFACD', // Lemon Chiffon
    '#FFF8DC', // Cornsilk
    '#F5F5F5', // White Smoke
    '#F0FFFF', // Azure
    '#E6E6FA', // Lavender
    '#FFF0F5', // Lavender Blush
    '#FFD700', // Gold (lighter)
    '#F0E68C', // Khaki (lighter)
    '#E0FFFF', // Light Cyan
    '#87CEFA', // Light Sky Blue
    '#B0E0E6', // Powder Blue
    '#ADD8E6', // Light Blue
    '#B0C4DE', // Light Steel Blue
    '#FFE4E1', // Misty Rose
    '#98FB98', // Pale Green
    '#AFEEEE', // Pale Turquoise
    '#D8BFD8', // Thistle
    '#F5DEB3', // Wheat];
  ];



  const getProjects = async () => {
    let { data, error } = await supabase
      .from('publish_details')
      .select(
        `
        url,
        title,
       description,
      author,
      projects ( id, name )
      `)


    if (error) {
      console.warn(error);
      return;
    }
    console.log(data)

    return data;
  };

  useEffect(() => {
    (async () => {
      const projectData = await getProjects();
      setProjects(projectData);
    })();

    return () => {};
    
  }, []);



  return (
    <div>
      <h1>Discover Pages by Everyone</h1>
      {/* <h2>Your Projects</h2> */}
      <div className='projects-container'>
        <div id='yourProjects'>
            
            {   
              projects?.map((project, index) => {
                const bgColor = COLORS[index % COLORS.length];
                const projectName = project.projects ? project.projects.name : 'logo'; 
                const imageURL = `https://${REACT_APP_ENV === 'development' ? 'dev-' : ''}storage.plexbox.app/images/screenshots/${projectName}.png`;
                console.log(imageURL)
                return (
                  <div 
                    className='project-box new-project-box' 
                    onClick={(e) => {
                      e.stopPropagation();
                      
                      
                      if (process.env.REACT_APP_ENV === 'development') {
                        window.open('https://dev.plexbox.app/' + project.url, '_blank');
                      } else {
                        // if prod or env variable doesnt exist
                        window.open('https://' + project.url + '.plexbox.app/', '_blank');
                      }
                    
                  }}
                    key={project.id} 
                    style={{backgroundColor: bgColor}}
                  >
                      
                    
                      <div className='box-container' style={{
                      backgroundImage: `url('${imageURL}')`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height : '85%',
                      width : '85%',
                      position : 'absolute',
                      top : '0.8rem',
                      opacity : '0.8'
                    }}>

                      <h3>{project.title}</h3>
                      <p>by {project.author}</p>
                      </div>
                     
                   
                  </div>
                )
              })
            }

        </div>
      </div>
    </div>
  );
};

export default withRouter(Discover);
