// Initialize IndexedDB
export function initializeIndexedDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('canvasDB', 1);
  
      request.onupgradeneeded = function(event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('tempState')) {
          db.createObjectStore('tempState', { autoIncrement: true });
        }        
      };
  
      request.onsuccess = function() {    
        resolve();
      };
  
      request.onerror = function(event) {
        reject(event.error);
      };
    });
  }
  
  
 // Save state to IndexedDB
export function saveToIndexedDB(data, uniqueKey) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('canvasDB', 1);
    request.onsuccess = () => {
      const db = request.result;
      const tx = db.transaction('tempState', 'readwrite');
      const store = tx.objectStore('tempState');
      const putRequest = store.put(data, uniqueKey);  // Using a fixed key 1 

      putRequest.onsuccess = () => {
        resolve();
      };

      putRequest.onerror = (event) => {
        reject(event.error);
      };
    };

    request.onerror = (event) => {
      reject(event.error);
    };
  });
}
  
// Load state from IndexedDB
export function loadFromIndexedDB(uniqueKey) {
  return new Promise((resolve, reject) => {
      const request = indexedDB.open('canvasDB', 1);

      request.onsuccess = () => {
          const db = request.result;
          const tx = db.transaction('tempState', 'readonly');
          const store = tx.objectStore('tempState');
          const getRequest = store.get(uniqueKey);  
          

          getRequest.onsuccess = () => {resolve(getRequest.result);}
          getRequest.onerror = () => reject(new Error('Failed to get data from store.'));
      };

      request.onerror = () => {
          reject(new Error('Failed to open database.'));
      };
  });
}




  