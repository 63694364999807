/* eslint-disable no-unused-vars */
import React, { useState ,useRef, useEffect} from 'react';

// Custom hook for drag
const useDrag = (isActive, updateElem, props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0 });
  const [currentDelta, setCurrentDelta] = useState({ dx: 0, dy: 0 });

  const DRAGINDEX = 100;

  const handleDragMouseDown = (e) => {
    if (e.target.classList.contains('resizable-handle')) {
      return; // Return early if the target is a resize handle
    }
    // console.log('mouse down, start drag');
    setIsDragging(true);
    setInitialPos({
      x: e.clientX,
      y: e.clientY,
    });
    // props.style.zIndex = parseInt(props.style.zIndex, 10) + DRAGINDEX;
    // move elem to the front
    updateElem({...props, style : {
      ...props.style, 
      zIndex : props.zIndex + DRAGINDEX,
    }}, props.index);
  };

  const handleDragMouseMove = (e) => {
    // console.log('mouse move, dragging now');
    if (isDragging) {
      const dx = e.clientX - initialPos.x;
      const dy = e.clientY - initialPos.y;
      setCurrentDelta({ dx, dy });
    }
  };

  const handleDragMouseUp = () => {
    // console.log('mouse up, stop drag');
    if (isDragging)  {
      setIsDragging(false);
      // props.style.zIndex = parseInt(props.style.zIndex, 10) - DRAGINDEX;
      const updatedStyle = {
        ...props.style,
        top: `${parseInt(props.style.top || '0', 10) + currentDelta.dy}px`,
        left: `${parseInt(props.style.left || '0', 10) + currentDelta.dx}px`,
        zIndex : props.zIndex,
      };

      updateElem({ ...props, style: updatedStyle }, props.index);
      setCurrentDelta({ dx: 0, dy: 0 }); // Reset deltas
    }
  };

  return { isDragging, handleDragMouseDown, handleDragMouseMove, handleDragMouseUp, currentDelta };
};


// Custom hook for resize
const useResize = (isActive, updateElem, props) => {
  const [isResizing, setIsResizing] = useState(false);
  const initialSize = useRef({ width: 0, height: 0 });
  const initialMousePos = useRef({ x: 0, y: 0 });
  let activeCorner = null;

  const handleResizeMouseDown = (e, corner) => {
    e.stopPropagation();
    setIsResizing(true);
    activeCorner = corner; // Set the active corner
    initialSize.current = { width: props.style.width, height: props.style.height, top: props.style.top, left: props.style.left };
    initialMousePos.current = { x: e.clientX, y: e.clientY };
    window.addEventListener('mousemove', handleResizeMouseMove);
    window.addEventListener('mouseup', handleResizeMouseUp);
  };

  const handleResizeMouseMove = (e) => {
    const dx = e.clientX - initialMousePos.current.x;
    const dy = e.clientY - initialMousePos.current.y;

    let updatedWidth = parseInt(initialSize.current.width || '0', 10);
    let updatedHeight = parseInt(initialSize.current.height || '0', 10);
    let updatedTop = parseInt(initialSize.current.top || '0', 10);
    let updatedLeft = parseInt(initialSize.current.left || '0', 10);

    // Apply different resizing behaviors based on the active corner
    switch (activeCorner) {
      case 'bottom-right':
        updatedWidth += dx;
        updatedHeight += dy;
        break;
      case 'top-right':
        updatedWidth += dx;
        updatedHeight -= dy;
        updatedTop += dy;
        break;
      case 'top-left':
        updatedWidth -= dx;
        updatedHeight -= dy;
        updatedTop += dy;
        updatedLeft += dx;
        break;
      case 'bottom-left':
        updatedWidth -= dx;
        updatedHeight += dy;
        updatedLeft += dx;
        break;
      default:
        break;
    }

    const updatedStyle = {
      ...props.style,
    width: `${updatedWidth}px`,
    height: `${updatedHeight}px`,
    top: `${updatedTop}px`,
    left: `${updatedLeft}px`,
    };

    updateElem({ ...props, style: updatedStyle }, props.index);
  };

  const handleResizeMouseUp = () => {
    setIsResizing(false);
    // console.log('mouse up active, stop resizing');
    window.removeEventListener('mousemove', handleResizeMouseMove);
    window.removeEventListener('mouseup', handleResizeMouseUp);
  };

  return { isResizing, handleResizeMouseDown, handleResizeMouseMove, handleResizeMouseUp };
};

const ActiveWrapper = ({ isActive, children, updateElem, setIsDragging }) => {
  const child = React.Children.only(children);
  // const toolbar = ActiveChildren[0];
  // const child = ActiveChildren[1];
  const props = child.props;

  const { isDragging, handleDragMouseDown, handleDragMouseMove, handleDragMouseUp, currentDelta } = useDrag(
    isActive,
    updateElem,
    props
  );

  const { isResizing, handleResizeMouseDown, handleResizeMouseMove, handleResizeMouseUp } = useResize(
    isActive,
    updateElem,
    props
  );

  //add drag mouse move and up listneres and remove them when isDragging is true
  useEffect(()=>{
    if(isDragging) {
      setIsDragging(true);
      window.addEventListener('mousemove', handleDragMouseMove);
      window.addEventListener('mouseup', handleDragMouseUp);

      return () => {
        setIsDragging(false);
        window.removeEventListener('mousemove', handleDragMouseMove);
        window.removeEventListener('mouseup', handleDragMouseUp);
      }
    }
  }, [isDragging, handleDragMouseMove, handleDragMouseUp]);

  //don't show select borders or resize handles for image dialog box. Ideally dialogboxes should be treated differently from all elements.
  let imageDialogBox = props.type === 'Image' && props.mode === 'edit' ? true : false;

  const enhancedStyle = isActive && !imageDialogBox
    ? { ...props.style, outline: '3px solid #cb6ce5', cursor: isResizing ? 'se-resize' : 'grab' }
    : props.style;
  
  const resizeHandles = isActive && !imageDialogBox && (
    <>
    <div className="resizable-handle handle-bottom-right" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-right')}
    />
    <div className="resizable-handle handle-top-right" onMouseDown={(e) => handleResizeMouseDown(e, 'top-right')}
    />
    <div className="resizable-handle handle-top-left" onMouseDown={(e) => handleResizeMouseDown(e, 'top-left')}
    />
    <div className="resizable-handle handle-bottom-left" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-left')}

    />
  </>
  );

  const clonedChild = React.cloneElement(child, {
    style: {
      ...enhancedStyle,
      transform: isDragging ? `translate(${currentDelta.dx}px, ${currentDelta.dy}px)` : 'none',
    },
    children: (
      <>
        {resizeHandles}
      </>
    ),
    
    
  });
  // console.log('child', child);
  return <div onMouseDown={handleDragMouseDown} className='activeWrapperDiv'>
    {clonedChild}
  </div>;
};

export default ActiveWrapper;