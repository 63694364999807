import React, { useContext } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import UserContext from '../../contexts/user';

const RequireAuth = function ({ children, supabase }) {
  const { session } = useContext(UserContext);

  if (session !== null) {
    return <>{children}</>;
  } else {
    return (
      <Auth
        providers={['google']}
        onlyThirdPartyProviders
        supabaseClient={supabase}
        redirectTo={`${window.location.origin}`}
        appearance={{ theme: ThemeSupa }}
      />
    );
  }
};

export default RequireAuth;
