
import React, {createContext, useReducer} from 'react';

//initial state of the context
const initialState = {
    canvasConfig : {
        projectName : '',
        backgroundColor : '',
        canvasHeight : '',
        canvasScreenshotUrl : ''
    },
    canvasElements: [],
};

//create context
export const CanvasContext = createContext(initialState);

const canvasReducer = (state, action) => {
    switch(action.type) {
        case 'ADD_ELEM': 
            return {
                ...state,
                canvasElements : [...state.canvasElements, action.payload]
            };
        case 'UPDATE_ELEM': 
            return {
                ...state,
                canvasElements : [...state.canvasElements.map((elem, index) => index === action.payload.index ? action.payload.elem : elem)]
            }
        case 'DEL_ELEM': 
            return {
                ...state, 
                canvasElements : [...state.canvasElements.filter((elem, index) => index !== action.payload.index)].map((elem, index) => ({
                    ...elem,
                    index: index, // Update the 'index' property to match the updated index
                }))
            };
        // SLOAD_SAVED_STATE is used to populate canvasElements with saved or loaded data, 
        // from IndexedDB. This is useful for scenarios where you want to load a saved state of the canvas 
        // from the database and populate the application state (canvasElements) with that data.
        case 'LOAD_SAVED_STATE':
            return {
                ...state,
                canvasElements: action.payload
            };
        case 'UPDATE_CANVAS_CONFIG' : 
            return {
                ...state,
                canvasConfig : action.payload
            };
        default: 
            return state;
    }
};

export const CanvasProvider = ({ children }) => {
    
    const [state, dispatch] = useReducer(canvasReducer, initialState);

    function addElem(elem) {
        dispatch({
            type: 'ADD_ELEM',
            payload : elem
        });
    }
    
    function updateElem(elem, index) {
        dispatch({
            type: 'UPDATE_ELEM',
            payload : {elem, index}
        })
    }
  
    function delElem(elem, index) {
        dispatch({
            type: 'DEL_ELEM',
            payload: { elem, index },
        });
    }

    // New function to load saved state
    function loadSavedState(savedState) {
        dispatch({
            type: 'LOAD_SAVED_STATE',
            payload: savedState
        });
    }

    function updateCanvasConfig(newConfig) {
        dispatch({
            type: 'UPDATE_CANVAS_CONFIG',
            payload: newConfig
        });
    }

    return (
        <CanvasContext.Provider
            value={{
                canvasElements : state.canvasElements,
                addElem,
                updateElem,
                delElem,
                loadSavedState, 
                updateCanvasConfig
            }}
        >
            {children}
            {/* {console.log('state..:', state)} */}
        </CanvasContext.Provider>
    );
};

