import {
  BOLD,
  COLOR,
  CORNER,
  FONT_SIZE,
  FONT_TYPE,
  HEADING,
  ITALIC,
  LAYERUP,
  LAYERDOWN,
  LINK,
  UNDERLINE,
  FILL,
  EDIT,
  TEXT_VARIANT,
  PARAGRAPH,
  SMALL_TEXT,
} from './constants';

import linkIcon from '../../assets/icons/link-tool.png';
import layerUpIcon from '../../assets/icons/layer-up.png';
import layerDownIcon from '../../assets/icons/layer-down.png';

const toolDefinitions = {
  [HEADING]: {
    label: 'Heading',
    type: 'dropdown',
    styleKey: 'fontSize',
    options: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    values: {
      H1: '2em',
      H2: '1.5em',
      H3: '1.17em',
      H4: '1em',
      H5: '0.83em',
      H6: '0.67em',
    },
  },
  [FONT_TYPE]: {
    label: 'FontType',
    type: 'dropdown',
    styleKey: 'fontFamily',
    options: ['Arial', 'Courier New', 'Times New Roman'],
    values: {
      Arial: 'Arial, sans-serif',
      'Courier New': 'Courier New, monospace',
      'Times New Roman': 'Times New Roman, serif',
    },
  },
  [LINK]: {
    label: 'Link',
    type: 'button',
    placeholder: 'Enter URL',
    customProperty: 'href',
    displayValue: 'L',
    icon: linkIcon,
  },
  [FONT_SIZE]: {
    label: 'FontSize',
    type: 'input',
    styleKey: 'fontSize',
    onValue: '16',
    suffix: 'px',
  },
  [BOLD]: {
    label: 'Bold',
    type: 'button',
    styleKey: 'fontWeight',
    displayValue: 'B',
    onValue: 'bold',
    offValue: 'normal',
  },
  [ITALIC]: {
    label: 'Italic',
    type: 'button',
    styleKey: 'fontStyle',
    displayValue: 'i',
    onValue: 'italic',
    offValue: 'normal',
  },
  [UNDERLINE]: {
    label: 'Underline',
    type: 'button',
    styleKey: 'textDecoration',
    displayValue: 'U',
    onValue: 'underline',
    offValue: 'none',
  },
  [COLOR]: {
    label: 'Color',
    type: 'input',
    placeholder: 'Enter Color Value',
    inputType: 'color',
    styleKey: 'color',
  },
  [CORNER]: {
    label: 'Corner Radius',
    type: 'input',
    placeholder: 'Radius in px',
    suffix: 'px',
    inputType: 'number',
    styleKey: 'borderRadius',
  },
  [LAYERUP]: {
    label: 'LayerUp',
    type: 'button',
    placeholder: 'Move up',
    customProperty: 'layer_up',
    styleKey: 'zIndex',
    // value : 0,
    displayValue : 'UP',
    icon: layerUpIcon
  },
  [LAYERDOWN]: {
    label: 'LayerDown',
    type: 'button',
    placeholder: 'Move down',
    customProperty: 'layer_down',
    styleKey: 'zIndex',
    displayValue : 'DOWN',
    icon: layerDownIcon
  },
  [FILL]: {
    label: 'Fill',
    type: 'input',
    placeholder: 'Enter Fill Color Value',
    inputType: 'color',
    styleKey: 'background',
  },
  [EDIT] : {
    label: 'Edit',
    type: 'button',
    styleKey: 'fontWeight',
    displayValue: 'EDIT',
    onValue: 'bold',
    offValue: 'normal',
  },
  [TEXT_VARIANT]: {
    label: 'Variant',
    type: 'dropdown',
    styleKey: 'variant',
    options: ['Heading', 'Paragraph', 'Small text'],
    values: {
      Heading: HEADING,
      Paragraph: PARAGRAPH,
      'Small Text': SMALL_TEXT,
    },
  },
};

export default toolDefinitions;
